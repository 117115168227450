export const FilterImageCarouselMappings = {
  links: [
    {
      image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/0S0A6521_-_DRESSES_resized.jpg?v=1722565594',
      text: 'Dresses',
      href: '/collections/dresses',
    },
    {
      image:
        'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/220819HM-0964_-_PLAYSUITS_resized.jpg?v=1722565594',
      text: 'Playsuits',
      href: '/collections/playsuits',
    },
    {
      image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/AA6I3767_-_TOPS_resized.jpg?v=1722565594',
      text: 'Tops',
      href: '/collections/tops',
    },
    {
      image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/0H1A6706_-_BOTTOMS_resized.jpg?v=1722565594',
      text: 'Bottoms',
      href: '/collections/bottoms',
    },
    {
      image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/0S0A5308_-_SETS_CO-ORDS_resized.jpg?v=1722565594',
      text: 'Sets & Co-ords',
      href: '/collections/sets',
    },
    {
      image: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/0H1A3934_-_SWIM_resized.jpg?v=1722565594',
      text: 'Swim',
      href: '/collections/swim',
    },
  ],
};
